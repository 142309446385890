<template>
  <div class="widgetContainer widgetContainer--center loginProgramCode bg-white">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="goToPreviousScreen" />
        <p class="title">
          {{ $t('programCode_NavTitle') }}
        </p>
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form
        @keyup.enter.native="submitForm()"
        :model="user"
        :rules="rules"
        ref="user"
        @submit.prevent.native
        class="wise-form">
        <el-form-item prop="otp" :label="$t('programCode_Title')">
          <ProgramCode v-model="user.code" :autofocus="true" />
        </el-form-item>
        <p class="wise-form__info talk-to-us">
          {{ $t('programCode_Desc') }}
          <span @click="openHelp">
            {{ $t('kycStatus_inReview_buttonTitle') }}
          </span>
        </p>
      </el-form>
      <div class="widgetContainer__footer--fixed">
        <el-button
          type="primary"
          @click="submitForm()"
          :disabled="isDisabled"
          class="el-button__brand brand">
          {{ $t('confirm') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import ProgramCode from '@/components/ProgramCode';
import { mapActions, mapGetters } from 'vuex';
import loginMixins from '../mixins/login';

export default {
  name: 'Login',
  mixins: [loginMixins],
  computed: {
    ...mapGetters('user', ['getUserDetails']),
    isDisabled() {
      return !this.user.code || this.user.code.length != 4;
    }
  },
  components: {
    ProgramCode
  },
  data() {
    return {
      user: {
        code: ''
      },
      rules: {
        code: 'required'
      }
    };
  },
  created() {
    /** @type {import('@/modules/user/store/state').userState['userDetails']} */
    const user = this.getUserDetails;
    if (!user || !user.phone) {
      this.goToWelcomeScreen();
    } else if (!user.programCodeRequired) {
      this.goToWelcomeScreen();
    }
  },
  methods: {
    ...mapActions('user', ['verifyOtp', 'getPerson']),
    submitForm() {
      if (this.isDisabled) {
        return;
      }
      /** @type {import('@/modules/user/store/state').userState['userDetails']} */
      const user = this.getUserDetails;
      if (user && user.phone) {
        const loader = this.showLoader();
        this.verifyOtp({ otp: user.otp, programCode: this.user.code })
          .then(() => {
            setTimeout(() => {
              const loader = this.showLoader();
              this.getPerson()
                .then(() => {
                  setTimeout(() => {
                    this.loginSuccess();
                  }, 0);
                })
                .catch((e) => {
                  this.apiErrorMessage(e);
                })
                .finally(() => {
                  loader.close();
                });
            }, 0);
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
      }
    }
  }
};
</script>

<style lang="scss">
.loginProgramCode {
  .talk-to-us {
    span {
      color: var(--branding-primary);
      cursor: pointer;
    }
  }
}
</style>
